<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp :display-position="sportsConst.GAME_DISPLAYPOSITION_1x2"></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section" style="position: relative">

            <popup-comp></popup-comp>
            <!--<div style="width: 100%;text-align: center;line-height: 40px;font-weight: bold;color: yellow">
                샘플서버입니다. 절대 <span style="color: #ff4d4d;font-size: 19px">`입금`</span> 하지마세요!!!
            </div>-->
            <div class="main">
                <div class="main_img">
                    <!--                    <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">-->
                    <swiper :options="swiperOption">
                        <swiper-slide>
                            <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/images/main/ptn/main02.jpg" alt="" style="width: 100%">
                        </swiper-slide>
                        <swiper-slide>
                            <img src="../../assets/images/main/ptn/main03.jpg" alt="" style="width: 100%">
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>

                <div class="sub_img pc">
                    <div class="sub">
                        <router-link to="/sports" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/bn01.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link to="/sports_inplay" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/bn02.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link :to="{path:'/casinohonor',type:1}" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/bn03.jpg" alt="">
                        </router-link>
                    </div>
                    <div class="sub">
                        <router-link :to="{path:'/casinohonor',type:2}" tag="span" style="cursor: pointer">
                            <img src="../../assets/images/main/ptn/bn04.jpg" alt="">
                        </router-link>
                    </div>

                    <div class="sub pc">
                        <router-link to="/leisure/eospowerball1m" tag="span">
                            <img src="../../assets/images/main/ptn/bn05.jpg" alt="">
                        </router-link>
                    </div>
                </div>
                <!--                <div class="sub_mobile">-->
                <!--                     <div class="sub_mobile_main_button">-->
                <!--                         <router-link to="/recharge" tag="button" class="smmb" style="color: #8bbef7">충전</router-link>-->
                <!--                         <router-link to="/exchange" tag="button" class="smmb" style="color: #ff9100">환전</router-link>-->
                <!--                         <router-link to="" @click.native="changePoint" tag="button" class="smmb">포인트전환</router-link>-->
                <!--                         <router-link to="/message" tag="button" class="smmb">쪽지</router-link>-->
                <!--                         <router-link to="/customer_center" tag="button" class="smmb">문의</router-link>-->

                <!--                     </div>-->
                <!--                </div>-->
                <div class="sub_mobile">
                    <mobile-menu-items-comp></mobile-menu-items-comp>
                </div>

                <div class="noticeandresult">
                    <div class="nt">
                        <div class="nt_title">
                            공지사항
                            <router-link tag="span" to="/notice"
                                         style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
                            </router-link>
                        </div>
                        <div class="cont_warp">
                            <router-link tag="div" :to="{path: '/notice', query: {id:item.id,t: new Date().getTime()}}"
                                         v-for="item in noticeList"
                                         class="nt_cont">
                                <!--                            <span> <img src="../../assets/images/icon/common/notice.png"></span> {{item.title}}-->
                                <span style="color: #b2915c">NOTICE</span> {{item.title}}
                            </router-link>
                        </div>

                    </div>
                    <div class="nt">
                        <div class="nt_title">
                            이벤트
                            <router-link tag="span" to="/event"
                                         style="float: right;font-size: 12px;color: #fff9ff;cursor: pointer">+전체보기
                            </router-link>
                        </div>
                        <div class="cont_warp">
                            <router-link tag="div" :to="{path: '/event', query: {id:item.id,t: new Date().getTime()}}"
                                         v-for="item in eventList"
                                         class="nt_cont">
                                <!--                            <span> <img src="../../assets/images/icon/common/event.png"></span> {{item.title}}-->
                                <span style="color: #b2915c">EVENT</span> {{item.title}}
                            </router-link>
                        </div>

                    </div>
                </div>


                <div class="sub_mobile">
                    <right-bar-banner-comp></right-bar-banner-comp>
                </div>
            </div>
        </div>
        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart" :display-position="displayPosition"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>


        <mobile-foot-menus></mobile-foot-menus>


    </div>


</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import TopbarComp from "../../components/TopbarComp";
    import {swiper, swiperSlide} from "vue-awesome-swiper"
    import "swiper/dist/css/swiper.css";
    import FootComp from "../../components/FootComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import inplayConst from "../../common/inplayConst";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import {getSportsInplayGames} from "../../network/sportsInplayRequest";
    import {WEBSOCKET_URL} from "../../common/const";
    import {changePoint, getEvent, getNoticeList} from "../../network/userRequest";
    import {postionMixin} from "../../common/mixin";
    import MobileMenuItemsComp from "../../components/MobileMenuItemsComp";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsMainShowComp from "../../components/sports/SportsMainShowComp";
    import {getRecentlyEndGames, getRecentlyGames} from "../../network/sportsRequest";
    import PopupComp from "../../components/PopupComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import WarningSample from "../../components/WarningSample";
    import MobileFootMenus from "../../components/MobileFootMenus";

    export default {
        name: "Main",
        mixins: [postionMixin],
        components: {
            MobileFootMenus,
            WarningSample,
            PopupComp,
            SportsMainShowComp,
            RightButtonsComp,
            UserInfoComp,
            MobileMenuItemsComp,
            SportsBetCartComp,
            RightBarBannerComp,
            LeftBarComp, SportsLeftBarComp, FootComp, TopbarComp, RightBarComp, swiper, swiperSlide
        },
        data() {
            return {
                noticeList: [],
                eventList: [],
                endgameList: [],
                noticePageSize: 8,
                inplayList: [],
                wsData: null,
                websocket: null,
                path: WEBSOCKET_URL,
                position: "메인",
                sportsConst,
                inplayConst,
                displayPosition: this.$store.state.displayPostion,
                soccer: [],
                base: [],
                basket: [],
                hokey: [],
                esports: [],
                vol: [],
                swiperOption: {
                    pagination: {el: '.swiper-pagination'},
                    autoplay: {},
                    disableOnInteraction: false,
                    delay: 2000,
                },
            }
        },
        methods: {
            initUserInfo() {
                this.$store.dispatch('actionUserInfo')
            },
            changePoint() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                changePoint().then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (!res.data.success) {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.initUserInfo()
                    }
                })
            },
        },
        created() {
            // getRecentlyGames().then(res => {
            //     if (res.data.success) {
            //         let recentlryGames = res.data.data
            //         this.soccer = recentlryGames.soccer
            //         this.basket = recentlryGames.bascket
            //         this.base = recentlryGames.base
            //         this.vol = recentlryGames.vol
            //         this.hokey = recentlryGames.hockey
            //         this.esports = recentlryGames.esports
            //     }
            // })
            // getRecentlyEndGames().then(res => {
            //     this.endgameList = res.data.data
            // })
            getNoticeList(100).then(res => {
                this.noticeList = res.data.data
            })
            getEvent(1, 100).then(res => {
                this.eventList = res.data.data
            })
            this.$store.state.userinsertagentcode='';
            this.$store.state.ischeckedagentcode=false;
        }
    }
</script>
<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");

    .main .sub_img {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
    }

    .main .sub_img .sub {
        width: 19.5%;
        cursor: pointer;
    }

    .main .sub_img img {
        width: 100%;
    }

    .main .sub_img img:hover {
        opacity: .6;
        transition: .3s;
    }

    .main .sub_mobile {
        display: none;
        width: 100%;
    }

    .main .mobile {
        display: none;
    }

    .main .main_btns1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0 0;
        padding: 0 5px;
    }

    .main .main_btns1 img {
        width: 20px;
    }

    .main_btns1 .menu1 {
        width: 24.6%;
        background-color: #1f1f24;
        border: 1px solid #313138;
        line-height: 42px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        border-radius: 3px;
    }

    .main .main_btns2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        flex-wrap: wrap;
    }

    .main_btns2 .menu2 {
        width: 33%;
        background-color: #1f1f24;
        border: 1px solid #313138;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        margin-top: 5px;
        border-radius: 3px;
        padding: 20px 0px;
    }

    .main_btns2 .menu2 img {
        width: 40px;
        margin: 3px 0;
    }


    .noticeandresult {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 10px;
        color: #bcbcbc;
    }

    .noticeandresult .nt {
        width: 49.6%;
        text-align: left;
        padding: 0 0px;
        background-color: #0e0e0e;
        border: 1px solid #000000;
    }

    .noticeandresult .nt .nt_title {
        width: 100%;
        height: 42px;
        line-height: 42px;
        border-bottom: 2px solid #945d02;
        background: linear-gradient(180deg, #0e0e0e, #0e0e0e);
        padding: 0 10px;
        font-size: 18px;
        font-weight: bold;
        color: #fff9ff;
        margin-bottom: 10px;
    }

    .noticeandresult .nt .cont_warp {
        max-height: 280px;
        overflow-y: scroll;
        width: 100%;
    }

    .noticeandresult .nt .nt_cont {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-left: 10px;
        font-size: 14px;;
        font-weight: bold;
        cursor: pointer;
    }

    .noticeandresult .nt .nt_cont img {
        height: 20px;
    }

    .noticeandresult .res {
        width: 60%;
        text-align: right;
        padding: 0 10px;
    }

    .noticeandresult .res div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }


    @media screen and (max-width: 1024px) {
        .main .sub_img .sub {
            width: 49.5% !important;
            padding: 2px 0px;
            margin-bottom: 5px;
        }

        .main .sub_mobile {
            display: block !important;
        }

        .main .pc {
            display: none !important;
        }

        .main .mobile {
            display: block !important;
        }

        .noticeandresult {
            width: 100%;
            flex-wrap: wrap;
        }

        .noticeandresult .nt {
            width: 100% !important;
        }

        .noticeandresult .res {
            width: 100% !important;
            margin-top: 20px;
        }

        .main .sub_mobile_main_button {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            margin: 5px 0px;
        }

        .main .sub_mobile_main_button .smmb {
            width: 19.5%;
            height: 42px;
            background: linear-gradient(180deg, #3f3f3f, #151515);
            color: #efefef;
            font-weight: bold;
        }
    }


</style>
