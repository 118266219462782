<template>
    <div class="sports2">
        <div class="sports_item">
            <div class="s2_stork">
                <div class="stork_img">
                    <slot name="typeImg"></slot>

                </div>
                <div class="stork_name" @click="gameTypeChange">
                    <slot name="typeName"></slot>
                    <span style="color: deepskyblue;cursor: pointer;margin-top: 2px">See All</span>
                </div>
            </div>
            <div class="sports2_matches">
                <div class="smatch" v-for="(item,index) in games">
                    <div class="st">
                        <div>{{item.startTime|datef('MM월DD일')}}</div>
                        <div>
                            {{item.startTime|datef('HH:mm')}}
                        </div>
                    </div>
                    <div class="steam">
                        <div class="t1">{{item.homeTeamName}}</div>
                        <div class="t2"><span class="t2vs">vs</span> <span class="t2name">{{item.awayTeamName}}</span></div>
                    </div>
                    <div class="sleague">
                        {{item.league.leagueName}}
                    </div>
                    <div class="sbd">
                        <div class="hb"> {{item.homeTeamOdds}}</div>
                        <div class="db">
                            <span v-if="item.drawOdds > 0">{{item.drawOdds}}</span>
                            <span v-else> vs</span>
                        </div>
                        <div class="ab">{{item.awayTeamOdds}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import sportsConst from "../../common/sportsConst";
    import {RECEIVE_CHECKED_GAME_TYPE, RECEIVE_CHECKED_LEAUGE_DELALL} from "../../store/mutation-types";

    export default {
        name: "SportsMainShowComp",
        props: {
            games: {
                type: Array,
                default() {
                    return []
                }
            },
            gameType:{
                type: Number,
                default() {
                    return sportsConst.GAME_TYPE_SOCCER
                }
            }
        },
        methods:{
            gameTypeChange() {
                this.$store.commit(RECEIVE_CHECKED_GAME_TYPE, this.gameType)
                this.$store.commit(RECEIVE_CHECKED_LEAUGE_DELALL)
                if (this.$route.name !== 'sports') {
                    this.$router.push({path: '/sports'})
                    return false;
                }
            },
        },
        created() {

        }

    }
</script>

<style scoped>
    .sports2 {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 16px;

    }

    .sports2 .sports_item {
        width: 100%;
        background-color: #d2d2d2;
        min-height: 316px;
    }

    .sports2 .sports_item .s2_stork {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        background-color: #43444a;
        height: 48px;
        align-items: center;
    }

    .sports2 .sports_item .s2_stork .stork_img {
        padding: 0 12px;
    }

    .sports2 .sports_item .s2_stork .stork_img img {
        width: 24px;
        height: 24px;
    }

    .sports2 .sports_item .s2_stork .stork_name {
        padding-left: 10px;
        color: #fff9ff;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }

    .sports2 .sports_item .sports2_matches {
        width: 100%;
        padding: 16px;
    }

    .sports2 .sports_item .smatch {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        font-weight: 600;
        margin-bottom: 5px;
        color: #282828;
    }

    .sports2 .sports_item .smatch .st {
        width: 12%;
        background-color: #ececed;
        text-align: center;
        padding: 3px 0;
        border-radius: 2px;
    }

    .sports2 .sports_item .smatch .st div {
        padding: 2px 0;
    }

    .sports2 .sports_item .smatch .steam {
        width: 25%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .steam .t2vs{
        color: #c51a1b;
    }

    .sports2 .sports_item .smatch .sleague {
        width: 22%;
        font-size: 10px;
        font-weight: normal;
        color: #7b7b7b;
        box-sizing: border-box;
        padding-left: 5px;
    }

    .sports2 .sports_item .smatch .sbd{
        width: 46%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .sports2 .sports_item .smatch .sbd div{
        padding: 20px 0;
        border-radius: 2px;
    }
    .sports2 .sports_item .smatch .sbd .hb{
        width: 31%;
        text-align: center;
        background-color: #daf4ff;
        margin-right: 2px;
    }
    .sports2 .sports_item .smatch .sbd .db{
        width: 31%;
        text-align: center;
        background-color: #daf4ff;
        margin-right: 2px;
    }
    .sports2 .sports_item .smatch .sbd .ab{
        width: 31%;
        text-align: center;
        background-color: #daf4ff;
    }


    @media screen and (max-width: 1024px) {
        .sports2{
            width: 100% !important;
        }
        .sports2 .sports_item {
            width: 100% !important;
        }
        .sports2 .sports_item .smatch .st{
            width: 20% !important;
        }

        .sports2 .sports_item .smatch .steam {
            width: 30% !important;
        }

        .sports2 .sports_item .smatch .sleague {
            display: none!important;
        }

        .sports2 .sports_item .smatch .sbd{
            width: 50% !important;
        }

    }
</style>